import React, { useEffect, useState } from "react";
import CustomTitle from "../../custom/CustomTitle";
import { Grid, Switch, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomButton from "../../custom/CustomButton";
import CustomCard from "../../custom/CustomCard";
import { mainUrl } from "../costants";
import CustomSelect from "../../custom/CustomSelect";
import CustomDivider from "../../custom/CustomDivider";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomInput from "../../custom/CustomInput";
import {
  getElevators,
  getLastData,
} from "../../../api/services/elevatorService";
import CustomLoading from "../../custom/CustomLoading";
import {
  prepareConfigJson,
  prepareNetTestJson,
  prepareParamsJson,
  prepareSensorsJson,
} from "../../custom/utils";
import {
  checkZfsStatus,
  commit,
  getZfs,
  getZfsFile,
  init,
  uploadFiles,
} from "../../../api/services/configuratorService";
import { useSnackbar } from "notistack";
import CustomFeedback from "../../custom/CustomFeedback";

type ParamsElevatorProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
};

const ParamsElevator: React.FC<ParamsElevatorProps> = ({ setBreadcrumb }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const varIcn = queryParams.get("varIcn");
  const varId = queryParams.get("varId");
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState<boolean>(true);
  const [elevators, setElevators] = useState<any[]>([]);

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      <Typography color="#ffffff" key="2">
        {t("updateParameters")}
      </Typography>,
    ]);
  }, []);

  useEffect(() => {
    getElevators().then((res: any) => {
      if (res && res.installations) {
        setElevators([...res.installations]);
      }
      setLoading(false);
    });
  }, []);

  const [values, setValues] = useState<any>({
    installation: "",
    elevator: "",
    contattore_manovra: "",
    contattore_apertura_porte: "",
    contattore_chiusura_porte: "",
    th1: "",
    th2: "",
    speed: "",
    system_power_aggregation: "",
    system_power_triphase: false,
    system_power_value: "",
    doors_power_aggregation: "",
    doors_power_triphase: false,
    doors_power_value: "",
  });
  const [load, setLoad] = useState<boolean>(true);
  useEffect(() => {
    if (elevators) {
      const tmp = { ...values };
      if (varIcn) {
        const inst = elevators.filter(
          (e) => e.installation_container_id === varIcn
        );
        tmp.installation =
          inst && inst.length > 0
            ? inst[0]?.installation_container_name || ""
            : "";
      }
      if (varId) {
        tmp.elevator = varId;
      }
      setValues(tmp);
      setTimeout(() => {
        setLoad(false);
      }, 1000);
    }
  }, [elevators]);

  const [net, setNet] = useState<any>({});

  useEffect(() => {
    if (values.elevator && !load) {
      getZfs(values.elevator).then((res: any) => {
        if (res && res.files) {
          const netFile = res.files.find(
            (file: any) => file.path === "net.json"
          );
          const paramsFile = res.files.find(
            (file: any) => file.path === "params.json"
          );
          if (netFile) {
            getZfsFile(values.elevator, netFile.id).then((resFile) => {
              setNet(resFile);
            });
          }
          if (paramsFile) {
            getZfsFile(values.elevator, paramsFile.id).then((resFile: any) => {
              getLastData(values.elevator, "z", "*").then((res) => {
                if (
                  res &&
                  res.result &&
                  res.result.values &&
                  res.result.values.length > 0 &&
                  res.result.values[0].value &&
                  res.result.values[0].value.CH_type &&
                  res.result.values[0].value.p_limit &&
                  res.result.values[0].value.speed
                ) {
                  setValues({
                    ...values,
                    th1: res.result.values[0].value.p_limit[0] || "",
                    th2: res.result.values[0].value.p_limit[1] || "",
                    contattore_manovra:
                      res.result.values[0].value.CH_type[0] || "",
                    contattore_apertura_porte:
                      res.result.values[0].value.CH_type[1] || "",
                    contattore_chiusura_porte:
                      res.result.values[0].value.CH_type[2] || "",
                    speed: res.result.values[0].value.speed || "",
                    system_power_aggregation: resFile?.CA1?.rolling_mode || "",
                    system_power_triphase: resFile?.CA1?.three_phase || false,
                    system_power_value: resFile?.CA1?.offset || "",
                    doors_power_aggregation: resFile?.CA2?.rolling_mode || "",
                    doors_power_triphase: resFile?.CA2?.three_phase || false,
                    doors_power_value: resFile?.CA2?.offset || "",
                  });
                }
              });
            });
          }
        }
      });
    }
  }, [values.elevator, load]);

  const handleSubmit = () => {
    const netFile = {
      file: new File(
        [new TextEncoder().encode(JSON.stringify(net))] as BlobPart[],
        "net.json"
      ),
    };
    const paramsFile = {
      file: new File(
        [
          new TextEncoder().encode(
            JSON.stringify(
              prepareParamsJson(
                Number(values.th1),
                Number(values.th2),
                values.contattore_manovra as "NO" | "NC",
                values.contattore_apertura_porte as "NO" | "NC",
                values.contattore_chiusura_porte as "NO" | "NC",
                Number(values.speed),
                values.system_power_aggregation || "avg",
                values.system_power_triphase,
                Number(values.system_power_value),
                values.doors_power_aggregation || "avg",
                values.doors_power_triphase,
                Number(values.doors_power_value)
              )
            )
          ),
        ] as BlobPart[],
        "params.json"
      ),
    };
    const netTestFile = {
      file: new File(
        [
          new TextEncoder().encode(JSON.stringify(prepareNetTestJson)),
        ] as BlobPart[],
        "net_test.json"
      ),
    };
    const sensorsFile = {
      file: new File(
        [
          new TextEncoder().encode(JSON.stringify(prepareSensorsJson)),
        ] as BlobPart[],
        "sensors.json"
      ),
    };
    const configFile = {
      file: new File(
        [
          new TextEncoder().encode(JSON.stringify(prepareConfigJson)),
        ] as BlobPart[],
        "config.json"
      ),
    };
    init(values.elevator).then(
      async () =>
        await uploadFiles(values.elevator, [
          netFile,
          paramsFile,
          sensorsFile,
          configFile,
          netTestFile,
        ]).then(async (res: any) => {
          if (res && res.files) {
            await commit(values.elevator, true).then((r: any) => {
              if (r && r.version) {
                enqueueSnackbar(t("parametersSuccess"), {
                  variant: "success",
                });
                setValues({
                  th1: "",
                  th2: "",
                  speed: "",
                  elevator: "",
                  installation: "",
                  contattore_manovra: "",
                  contattore_apertura_porte: "",
                  contattore_chiusura_porte: "",
                });
                if (varIcn && varId) {
                  navigate(-1);
                }
              } else {
                enqueueSnackbar(t("parametersError"), {
                  variant: "error",
                });
              }
            });
          }
        })
    );
  };

  const [runningZfs, setRunningZfs] = useState<boolean>(false);
  useEffect(() => {
    const checkStatus = async () => {
      checkZfsStatus(values.elevator)
        .then((res) => {
          if (res && res.job && res.job.status) {
            if (res.job.status === "pending") {
              setRunningZfs(true);
            } else {
              setRunningZfs(false);
            }
          }
        })
        .catch((err) => {
          console.log("ERROR", err);
          setRunningZfs(false);
        });
    };
    if (values.elevator) {
      checkStatus();
      const intervalId = setInterval(checkStatus, 30000);
      return () => clearInterval(intervalId);
    }
  }, [values.elevator]);

  if (loading || load) {
    return <CustomLoading />;
  }

  return (
    <Grid
      container
      spacing={4}
      justifyContent="center"
      style={{ padding: "100px 32px" }}
    >
      <Grid item xs={12}>
        <CustomTitle
          title={t("updateParameters")}
          goBack={() => navigate(-1)}
        />
      </Grid>
      {runningZfs && (
        <Grid item xs={12}>
          <CustomFeedback label={t("workInProgress")} type="warning" />
        </Grid>
      )}
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} md={6}>
                <CustomSelect
                  label={`${t("installationName")}`}
                  value={values.installation}
                  handleChange={(e) =>
                    setValues({ ...values, installation: e.target.value })
                  }
                  options={[
                    ...elevators
                      .map((e) => {
                        return {
                          label: `${e.installation_container_name}`,
                          value: e.installation_container_name,
                        };
                      })
                      .filter((e, index) => {
                        return (
                          index ===
                          elevators.findIndex((el) => {
                            return el.installation_container_name === e.label;
                          })
                        );
                      }),
                  ]}
                  disabled={varIcn && varId ? true : false}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  label={`${t("systemNumber")}`}
                  value={values.elevator}
                  handleChange={(e) =>
                    setValues({ ...values, elevator: e.target.value })
                  }
                  options={[
                    ...elevators
                      .filter((e) => {
                        return (
                          e.installation_container_name === values.installation
                        );
                      })
                      .map((e) => {
                        return {
                          label: `${e.system_number} (${e.id})`,
                          value: e.id,
                        };
                      }),
                  ]}
                  disabled={varIcn && varId ? true : !values.installation}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomDivider
                  label={`${t("updateParameters")}`}
                  textAlign="center"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("maximumEnginePower")}
                  type="number"
                  value={values.th1}
                  handleChange={(e: any) => {
                    setValues({ ...values, th1: e.target.value });
                  }}
                  endAdornment="W"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("standbyPowerLimit")}
                  type="number"
                  value={values.th2}
                  handleChange={(e: any) => {
                    setValues({ ...values, th2: e.target.value });
                  }}
                  endAdornment="W"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  options={[
                    {
                      label: "NO",
                      value: "NO",
                    },
                    {
                      label: "NC",
                      value: "NC",
                    },
                  ]}
                  label={`${t("switchingContactor")}`}
                  value={values.contattore_manovra}
                  handleChange={(e) =>
                    setValues({ ...values, contattore_manovra: e.target.value })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  options={[
                    {
                      label: "NO",
                      value: "NO",
                    },
                    {
                      label: "NC",
                      value: "NC",
                    },
                  ]}
                  label={`${t("doorOpeningContactor")}`}
                  value={values.contattore_apertura_porte}
                  handleChange={(e) =>
                    setValues({
                      ...values,
                      contattore_apertura_porte: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomSelect
                  options={[
                    {
                      label: "NO",
                      value: "NO",
                    },
                    {
                      label: "NC",
                      value: "NC",
                    },
                  ]}
                  label={`${t("doorClosingContactor")}`}
                  value={values.contattore_chiusura_porte}
                  handleChange={(e) =>
                    setValues({
                      ...values,
                      contattore_chiusura_porte: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomInput
                  label={t("averageSpeed")}
                  type="number"
                  value={values.speed}
                  handleChange={(e: any) => {
                    setValues({ ...values, speed: e.target.value });
                  }}
                  endAdornment="m/s"
                />
              </Grid>
            </Grid>
          }
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <CustomCard
          content={
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12}>
                <CustomDivider
                  label={`${t("systemPower")}`}
                  textAlign="center"
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <CustomSelect
                  label={`${t("aggregation")}`}
                  options={[
                    { value: "min", label: t("min") },
                    { value: "max", label: t("max") },
                    { value: "avg", label: t("avg") },
                  ]}
                  value={values.system_power_aggregation}
                  handleChange={(e) =>
                    setValues({
                      ...values,
                      system_power_aggregation: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Switch
                  checked={values.system_power_triphase}
                  onChange={() =>
                    setValues({
                      ...values,
                      system_power_triphase: !values.system_power_triphase,
                    })
                  }
                />
                {t("triphase")}
              </Grid>
              <Grid item xs={12} md={5}>
                <CustomInput
                  type="number"
                  label={`${t("value")}`}
                  value={values.system_power_value}
                  handleChange={(e: any) =>
                    setValues({ ...values, system_power_value: e.target.value })
                  }
                  endAdornment="W"
                />
              </Grid>
              <Grid item xs={12}>
                <CustomDivider
                  label={`${t("doorsPower")}`}
                  textAlign="center"
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <CustomSelect
                  label={`${t("aggregation")}`}
                  options={[
                    { value: "min", label: t("min") },
                    { value: "max", label: t("max") },
                    { value: "avg", label: t("avg") },
                  ]}
                  value={values.doors_power_aggregation}
                  handleChange={(e) =>
                    setValues({
                      ...values,
                      doors_power_aggregation: e.target.value,
                    })
                  }
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <Switch
                  checked={values.doors_power_triphase}
                  onChange={() =>
                    setValues({
                      ...values,
                      doors_power_triphase: !values.doors_power_triphase,
                    })
                  }
                />
                {t("triphase")}
              </Grid>
              <Grid item xs={12} md={5}>
                <CustomInput
                  type="number"
                  label={`${t("value")}`}
                  value={values.doors_power_value}
                  handleChange={(e: any) =>
                    setValues({ ...values, doors_power_value: e.target.value })
                  }
                  endAdornment="W"
                />
              </Grid>
            </Grid>
          }
        />
      </Grid>
      <Grid item container xs={12} md={8} spacing={2}>
        <Grid item xs={12} md>
          <CustomButton
            fullWidth
            label={t("reset")}
            onClick={() =>
              setValues({
                installation: "",
                elevator: "",
                contattore_manovra: "",
                contattore_apertura_porte: "",
                contattore_chiusura_porte: "",
                th1: "",
                th2: "",
                speed: "",
                system_power_aggregation: "",
                system_power_triphase: false,
                system_power_value: "",
                doors_power_aggregation: "",
                doors_power_triphase: false,
                doors_power_value: "",
              })
            }
            confirm
            type="outlined"
          />
        </Grid>
        <Grid item xs={12} md>
          <CustomButton
            fullWidth
            label={t("ok")}
            onClick={handleSubmit}
            type="contained"
            disabled={runningZfs}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ParamsElevator;
