import React, { useEffect, useState } from "react";
import { Grid, Stack, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CustomTitle from "../../custom/CustomTitle";
import { useNavigate, useParams } from "react-router-dom";
import CustomList from "../../custom/CustomList";
import CustomCard from "../../custom/CustomCard";
import CustomTag from "../../custom/CustomTag";
import "./style.css";
import {
  companyListToChartUrl,
  elevatorListToChartUrl,
  mainUrl,
  paramsElevatorUrl,
  roles,
} from "../costants";
import CustomIconButton from "../../custom/CustomIconButton";
import {
  ArrowDownward,
  ArrowUpward,
  Pause,
  Stop,
  Edit,
} from "@mui/icons-material";
import { Link } from "react-router-dom";
import CustomLoading from "../../custom/CustomLoading";
import {
  getElevator,
  getLastData,
} from "../../../api/services/elevatorService";
import { darkColor, dashboardUrl, formatAddress } from "../../custom/utils";
import {
  checkZfsStatus,
  getZfs,
  getZfsFile,
} from "../../../api/services/configuratorService";
import { elevateGetData } from "../../../api/services/elevateService";
import CustomFeedback from "../../custom/CustomFeedback";

type InstallationPageProps = {
  setBreadcrumb: React.Dispatch<React.SetStateAction<any[]>>;
  companyInfo: boolean;
  role: string;
};

const InstallationPage: React.FC<InstallationPageProps> = ({
  setBreadcrumb,
  companyInfo,
  role,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();

  const [loading, setLoading] = useState<boolean>(true);
  const [elevator, setElevator] = useState({
    company: "",
    name: "",
    status: "",
    systemNumber: "",
    systemSerialNumber: "",
    address: "",
    idGestionale: "",
    installerName: "",
    mantainerName: "",
    type: "",
    icn: "",
    installationDate: "",
    idElevate: "",
  });
  const [loadParams, setLoadParams] = useState<boolean>(true);
  const [params, setParams] = useState<any>({
    switchingContactor: "N/A",
    doorOpeningContactor: "N/A",
    doorClosingContactor: "N/A",
    averageSpeed: "N/A",
    maximumEnginePower: "N/A",
    standbyPowerLimit: "N/A",
    systemPowerAggregation: "N/A",
    systemPowerTriphase: "N/A",
    systemPowerValue: "N/A",
    doorsPowerAggregation: "N/A",
    doorsPowerTriphase: "N/A",
    doorsPowerValue: "N/A",
  });
  const [runningZfs, setRunningZfs] = useState<boolean>(false);
  const [elevateData, setElevateData] = useState<any>({
    id_elevate: "",
    online: false,
    status: "",
    current_floor: 0,
    engine_status: "",
    busy: false,
    overload: false,
    out_of_order: false,
    blocking_err: "",
    door_status: [],
    alive_datetime: "",
    status_datetime: "",
    created_at: "",
    deleted_at: null,
  });

  useEffect(() => {
    setBreadcrumb([
      <Link style={{ color: "#ffffff" }} key="1" to={mainUrl}>
        {t("home")}
      </Link>,
      ...(companyInfo
        ? [
            <Link
              style={{ color: "#ffffff" }}
              key="2"
              to={companyListToChartUrl}
            >
              {t("companyOverview")}
            </Link>,
          ]
        : [
            <Link
              style={{ color: "#ffffff" }}
              key="2"
              to={elevatorListToChartUrl("all")}
            >
              {t("elevatorOverview")}
            </Link>,
          ]),
      <Typography color="#ffffff" key="4">
        {`${t("elevatorDetails")}: ${elevator.name}`}
      </Typography>,
    ]);
  }, [elevator]);

  useEffect(() => {
    getElevator(id!).then((res) => {
      if (res && res.installation) {
        setElevator({
          idElevate: res.installation?.id_elevate,
          company: res.installation?.azienda,
          address: formatAddress(res.installation?.installation_info),
          name: res.installation.name,
          status: res.installation.status,
          systemNumber: res.installation.system_number,
          systemSerialNumber: res.installation.system_serial_number,
          idGestionale: res.installation?.id_gestionale,
          installerName: res.installation?.actual_installer_name,
          mantainerName: res.installation?.manutentore_assigned_name,
          type: res.installation?.model,
          icn: res.installation?.installation_container_id,
          installationDate: res.installation?.created_at,
        });
      }
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    const checkStatus = async () => {
      checkZfsStatus(id!).then((res) => {
        if (res && res.job && res.job.status) {
          if (res.job.status === "pending") {
            setRunningZfs(true);
          } else {
            setRunningZfs(false);
          }
        }
      });
    };
    checkStatus();
    const intervalId = setInterval(checkStatus, 30000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (id) {
      getZfs(id).then((res: any) => {
        if (res && res.files) {
          const paramsFile = res.files.find(
            (file: any) => file.path === "params.json"
          );
          if (paramsFile) {
            getZfsFile(id, paramsFile.id).then((resFile: any) => {
              getLastData(id, "z", "*").then((res) => {
                if (
                  res &&
                  res.result &&
                  res.result.values &&
                  res.result.values.length > 0 &&
                  res.result.values[0].value &&
                  res.result.values[0].value.CH_type &&
                  res.result.values[0].value.p_limit &&
                  res.result.values[0].value.speed
                ) {
                  setParams({
                    switchingContactor: res.result.values[0].value.CH_type[0],
                    doorOpeningContactor: res.result.values[0].value.CH_type[1],
                    doorClosingContactor: res.result.values[0].value.CH_type[2],
                    averageSpeed: res.result.values[0].value.speed,
                    maximumEnginePower: res.result.values[0].value.p_limit[0],
                    standbyPowerLimit: res.result.values[0].value.p_limit[1],
                    systemPowerAggregation: resFile?.CA1?.rolling_mode || "N/A",
                    systemPowerTriphase: resFile?.CA1?.three_phase
                      ? String(resFile?.CA1?.three_phase)
                      : "false",
                    systemPowerValue: resFile?.CA1?.offset || "N/A",
                    doorsPowerAggregation: resFile?.CA2?.rolling_mode || "N/A",
                    doorsPowerTriphase: resFile?.CA2?.three_phase
                      ? String(resFile?.CA2?.three_phase)
                      : "false",
                    doorsPowerValue: resFile?.CA2?.offset || "N/A",
                  });
                }
                setLoadParams(false);
              });
            });
          }
        } else {
          setLoadParams(false);
        }
      });
    }
  }, [id, runningZfs]);

  useEffect(() => {
    if (id && elevator && elevator.systemNumber) {
      elevateGetData(id, elevator.systemNumber).then((res) => {
        if (res && res.elevate_data) {
          setElevateData(
            res.elevate_data.sort(
              (a: any, b: any) =>
                new Date(b.status_datetime).getTime() -
                new Date(a.status_datetime).getTime()
            )[0]
          );
        }
      });
    }
  }, [elevator]);

  const checkStatus = (status: string) => {
    switch (status) {
      case "SM_MOVELIFT":
        return "Movimento";
      case "SM_WAIT":
        return "Attesa";
      default:
        return status;
    }
  };

  const checkStatusColor = (status: string) => {
    switch (status) {
      case "SM_MOVELIFT":
        return "green";
      case "SM_WAIT":
        return "orange";
      default:
        return "grey";
    }
  };

  const checkLabelDoor = (door: string) => {
    switch (door) {
      case "DOOR_POSITION_CLOSED":
        return "Chiusa";
      case "DOOR_POSITION_OPEN":
        return "Aperta";
      case "DOOR_POSITION_UNKNOWN":
        return "Sconosciuta";
      default:
        return door;
    }
  };

  const checkEngineLabel = (engine: string) => {
    switch (engine) {
      case "LIFT_ENGINE_UP":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <ArrowUpward />
          </div>
        );
      case "LIFT_ENGINE_DOWN":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <ArrowDownward />
          </div>
        );
      case "LIFT_ENGINE_PAUSE":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Pause />
          </div>
        );
      case "LIFT_ENGINE_STOP":
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Stop />
          </div>
        );
      default:
        return engine;
    }
  };

  if (loading || loadParams) {
    return <CustomLoading />;
  }

  const maintainerResponsibleAndCompanyItems = [
    {
      primaryText: "Zerynth Device",
      action: (
        <CustomTag
          label={String(elevator.status).toLocaleUpperCase()}
          type="filled"
          color={
            String(elevator.status).toLocaleLowerCase() === "online"
              ? "green"
              : "red"
          }
        />
      ),
    },
    {
      primaryText: t("systemNumber"),
      action: elevator.systemNumber,
    },
    {
      primaryText: t("systemSerialNumber"),
      action: elevator.systemSerialNumber,
    },
    {
      primaryText: `${t("idGestionale")}`,
      action: elevator.idGestionale || "N/A",
    },
    {
      primaryText: `${t("installer")}`,
      action: elevator.installerName || "N/A",
    },
    {
      primaryText: `${t("mantainer")}`,
      action: elevator.mantainerName || "N/A",
    },
    {
      primaryText: `${t("installationDate")}`,
      action: new Date(elevator.installationDate).toLocaleString() || "N/A",
    },
    {
      primaryText: t("address"),
      action: (
        <div
          style={{ width: "100%", justifyContent: "flex-end", display: "flex" }}
        >
          <div style={{ width: "60%", fontSize: "16px", textAlign: "right" }}>
            {elevator.address}
          </div>
        </div>
      ),
    },
  ];
  const customerItems = [
    {
      primaryText: "Zerynth Device",
      action: (
        <CustomTag
          label={String(elevator.status).toLocaleUpperCase()}
          type="filled"
          color={
            String(elevator.status).toLocaleLowerCase() === "online"
              ? "green"
              : "red"
          }
        />
      ),
    },
    {
      primaryText: t("systemNumber"),
      action: elevator.systemNumber,
    },
    {
      primaryText: t("systemSerialNumber"),
      action: elevator.systemSerialNumber,
    },
    {
      primaryText: `${t("installationDate")}`,
      action: new Date(elevator.installationDate).toLocaleString() || "N/A",
    },
    {
      primaryText: t("address"),
      action: (
        <div
          style={{ width: "100%", justifyContent: "flex-end", display: "flex" }}
        >
          <div style={{ width: "60%", fontSize: "16px", textAlign: "right" }}>
            {elevator.address}
          </div>
        </div>
      ),
    },
  ];
  const installerItems = [
    {
      primaryText: "Zerynth Device",
      action: (
        <CustomTag
          label={String(elevator.status).toLocaleUpperCase()}
          type="filled"
          color={
            String(elevator.status).toLocaleLowerCase() === "online"
              ? "green"
              : "red"
          }
        />
      ),
    },
    {
      primaryText: t("systemNumber"),
      action: elevator.systemNumber,
    },
    {
      primaryText: t("systemSerialNumber"),
      action: elevator.systemSerialNumber,
    },
    {
      primaryText: `${t("idGestionale")}`,
      action: elevator.idGestionale || "N/A",
    },
    {
      primaryText: `${t("installationDate")}`,
      action: new Date(elevator.installationDate).toLocaleString() || "N/A",
    },
    {
      primaryText: t("address"),
      action: (
        <div
          style={{ width: "100%", justifyContent: "flex-end", display: "flex" }}
        >
          <div style={{ width: "60%", fontSize: "16px", textAlign: "right" }}>
            {elevator.address}
          </div>
        </div>
      ),
    },
  ];
  const maintainerItems = [
    {
      primaryText: "Zerynth Device",
      action: (
        <CustomTag
          label={String(elevator.status).toLocaleUpperCase()}
          type="filled"
          color={
            String(elevator.status).toLocaleLowerCase() === "online"
              ? "green"
              : "red"
          }
        />
      ),
    },
    {
      primaryText: t("systemNumber"),
      action: elevator.systemNumber,
    },
    {
      primaryText: t("systemSerialNumber"),
      action: elevator.systemSerialNumber,
    },
    {
      primaryText: `${t("idGestionale")}`,
      action: elevator.idGestionale || "N/A",
    },
    {
      primaryText: `${t("installer")}`,
      action: elevator.installerName || "N/A",
    },
    {
      primaryText: `${t("installationDate")}`,
      action: new Date(elevator.installationDate).toLocaleString() || "N/A",
    },
    {
      primaryText: t("address"),
      action: (
        <div
          style={{ width: "100%", justifyContent: "flex-end", display: "flex" }}
        >
          <div style={{ width: "60%", fontSize: "16px", textAlign: "right" }}>
            {elevator.address}
          </div>
        </div>
      ),
    },
  ];

  let getItemsListByRole = (role: string) => {
    switch (role) {
      case roles.customer:
        return customerItems;
      case roles.installer:
        return installerItems;
      case roles.maintainer:
        return maintainerItems;
      case roles.company:
      case roles.respMaintainer:
        return maintainerResponsibleAndCompanyItems;
      default:
        return customerItems;
    }
  };

  let paramLabels = (status: string) => {
    switch (status) {
      case "NO":
        return t("normallyOpened");
      case "NC":
        return t("normallyClosed");
      default:
        return "N/A";
    }
  };

  return (
    <>
      <Grid
        container
        spacing={2}
        justifyContent="center"
        style={{ padding: "100px 32px" }}
      >
        <Grid item xs={12}>
          <CustomTitle
            title={`${t("elevatorDetails")}: ${elevator.name}`}
            goBack={() => navigate(-1)}
          />
        </Grid>
        {runningZfs && (
          <Grid item xs={12}>
            <CustomFeedback label={t("workInProgress")} type="warning" />
          </Grid>
        )}
        <Grid item xs={12} md={role === roles.customer ? 4 : true}>
          <CustomCard
            fullHeight={role !== roles.customer}
            header={{
              visible: true,
              title: `${t("elevatorRegistry")}`,
            }}
            content={
              <Grid container>
                <Grid item xs={12}>
                  <CustomList listElement={getItemsListByRole(role)} />
                </Grid>
              </Grid>
            }
          />
        </Grid>
        {role !== roles.customer && (
          <Grid item xs={12} md>
            <CustomCard
              fullHeight
              header={{
                visible: true,
                title: `${t("parameters")}`,
                action: (
                  <>
                    {(role === roles.maintainer ||
                      role === roles.company ||
                      role === roles.respMaintainer ||
                      role === roles.installer) && (
                      <CustomIconButton
                        tooltip={`${t("updateParameters")}`}
                        disabled={runningZfs}
                        icon={<Edit />}
                        onClick={() =>
                          navigate(
                            paramsElevatorUrl +
                              `?varIcn=${elevator.icn}&varId=${id}`
                          )
                        }
                      />
                    )}
                  </>
                ),
              }}
              content={
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CustomList
                      dense
                      listElement={[
                        {
                          primaryText: t("switchingContactor"),
                          action: <>{paramLabels(params.switchingContactor)}</>,
                        },
                        {
                          primaryText: t("doorOpeningContactor"),
                          action: (
                            <>{paramLabels(params.doorOpeningContactor)}</>
                          ),
                        },
                        {
                          primaryText: t("doorClosingContactor"),
                          action: (
                            <>{paramLabels(params.doorClosingContactor)}</>
                          ),
                        },
                        {
                          primaryText: t("averageSpeed"),
                          action: <>{params.averageSpeed} m/s</>,
                        },
                        {
                          primaryText: t("maximumEnginePower"),
                          action: <>{params.maximumEnginePower} W</>,
                        },
                        {
                          primaryText: t("standbyPowerLimit"),
                          action: <>{params.standbyPowerLimit} W</>,
                        },
                        {
                          primaryText: t("systemPower"),
                          secondaryText:
                            elevator.type === "elevate" ? (
                              <Stack direction="row" spacing={1}>
                                <CustomTag
                                  label={`${t("aggregation")}: ${
                                    params.systemPowerAggregation
                                  }`}
                                  type="outlined"
                                />
                                <CustomTag
                                  label={`${t("triphase")}: ${
                                    params.systemPowerTriphase
                                  }`}
                                  type="outlined"
                                />
                                <CustomTag
                                  label={`${t("offset")}: ${
                                    params.systemPowerValue
                                  }`}
                                  type="outlined"
                                />
                              </Stack>
                            ) : undefined,
                          action:
                            elevator.type !== "elevate" ? (
                              <Stack direction="row" spacing={1}>
                                <CustomTag
                                  label={`${t("aggregation")}: ${
                                    params.systemPowerAggregation
                                  }`}
                                  type="outlined"
                                />
                                <CustomTag
                                  label={`${t("triphase")}: ${
                                    params.systemPowerTriphase
                                  }`}
                                  type="outlined"
                                />
                                <CustomTag
                                  label={`${t("offset")}: ${
                                    params.systemPowerValue
                                  }`}
                                  type="outlined"
                                />
                              </Stack>
                            ) : undefined,
                        },
                        {
                          primaryText: t("doorsPower"),
                          secondaryText:
                            elevator.type === "elevate" ? (
                              <Stack direction="row" spacing={1}>
                                <CustomTag
                                  label={`${t("aggregation")}: ${
                                    params.doorsPowerAggregation
                                  }`}
                                  type="outlined"
                                />
                                <CustomTag
                                  label={`${t("triphase")}: ${
                                    params.doorsPowerTriphase
                                  }`}
                                  type="outlined"
                                />
                                <CustomTag
                                  label={`${t("offset")}: ${
                                    params.doorsPowerValue
                                  }`}
                                  type="outlined"
                                />
                              </Stack>
                            ) : undefined,
                          action:
                            elevator.type !== "elevate" ? (
                              <Stack direction="row" spacing={1}>
                                <CustomTag
                                  label={`${t("aggregation")}: ${
                                    params.doorsPowerAggregation
                                  }`}
                                  type="outlined"
                                />
                                <CustomTag
                                  label={`${t("triphase")}: ${
                                    params.doorsPowerTriphase
                                  }`}
                                  type="outlined"
                                />
                                <CustomTag
                                  label={`${t("offset")}: ${
                                    params.doorsPowerValue
                                  }`}
                                  type="outlined"
                                />
                              </Stack>
                            ) : undefined,
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              }
            />
          </Grid>
        )}
        {role === roles.customer && (
          <Grid item xs={12} md spacing={2}>
            <Grid item xs={12}>
              <iframe
                id="elevator_iframe"
                title="elevator_iframe"
                className="responsive-iframe"
                src={`https://grafana.app.baglini.zerynth.com/d/ajN_oz1Sz/elevator?orgId=1&var-device_id=${id}&refresh=5s&kiosk=tv`}
                style={{
                  display: "block",
                  width: "100%",
                  height: "530px",
                  border: "none",
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <iframe
                id="grafana_iframe"
                title="grafana_iframe"
                className="responsive-iframe"
                src={dashboardUrl(role, id!)}
                style={{
                  display: "block",
                  width: "100%",
                  height: "530px",
                  border: "none",
                }}
              />
            </Grid>
          </Grid>
        )}
        {elevator.type === "elevate" && (
          <Grid item xs={12} md>
            <CustomCard
              fullHeight
              header={{
                visible: true,
                title: `${t("elevateCard")}`,
                action: (
                  <CustomTag
                    label={
                      elevateData.status_datetime
                        ? `${t("lastUpdate")}: ${elevateData.status_datetime}`
                        : `${t("noUpdate")}`
                    }
                    type="filled"
                    color={darkColor}
                  />
                ),
              }}
              content={
                <Grid container>
                  <Grid item xs={12}>
                    <CustomList
                      dense
                      listElement={[
                        {
                          primaryText: t("status"),
                          action: (
                            <>
                              <CustomTag
                                label={
                                  checkStatus(elevateData?.status) || "N/A"
                                }
                                type="filled"
                                color={checkStatusColor(elevateData?.status)}
                              />
                            </>
                          ),
                        },
                        {
                          primaryText: t("floor"),
                          action: (
                            <>{String(elevateData?.current_floor) || "N/A"}</>
                          ),
                        },
                        {
                          primaryText: t("motorStatus"),
                          action: (
                            <>
                              {checkEngineLabel(elevateData?.engine_status) ||
                                "N/A"}
                            </>
                          ),
                        },
                        {
                          primaryText: t("doorStatus") + " 1",
                          action: (
                            <>
                              {checkLabelDoor(elevateData?.door_status[0]) ||
                                "N/A"}
                            </>
                          ),
                        },
                        {
                          primaryText: t("doorStatus") + " 2",
                          action: (
                            <>
                              {checkLabelDoor(elevateData?.door_status[1]) ||
                                "N/A"}
                            </>
                          ),
                        },
                        {
                          primaryText: t("doorStatus") + " 3",
                          action: (
                            <>
                              {checkLabelDoor(elevateData?.door_status[2]) ||
                                "N/A"}
                            </>
                          ),
                        },
                        {
                          primaryText: t("overload"),
                          action: (
                            <>
                              {elevateData?.overload ? (
                                <CustomTag
                                  type="filled"
                                  color="red"
                                  label="Presente"
                                />
                              ) : (
                                <CustomTag
                                  type="filled"
                                  color="green"
                                  label="Non presente"
                                />
                              )}
                            </>
                          ),
                        },
                        {
                          primaryText: t("outOfOrder"),
                          action: (
                            <>
                              {elevateData?.out_of_order ? (
                                <CustomTag
                                  type="filled"
                                  color="red"
                                  label="Presente"
                                />
                              ) : (
                                <CustomTag
                                  type="filled"
                                  color="green"
                                  label="Non presente"
                                />
                              )}
                            </>
                          ),
                        },
                      ]}
                    />
                  </Grid>
                </Grid>
              }
            />
          </Grid>
        )}
        {role !== roles.superadmin && role !== roles.customer && (
          <Grid item xs={12}>
            <iframe
              id="grafana_iframe"
              title="grafana_iframe"
              className="responsive-iframe"
              src={dashboardUrl(role, id!)}
              style={{
                height:
                  role === roles.admin
                    ? "1350px"
                    : role === roles.company
                    ? "1050px"
                    : role === roles.respMaintainer
                    ? "2100px"
                    : role === roles.maintainer
                    ? "1750px"
                    : role === roles.installer
                    ? "950px"
                    : "4000px",
                display: "block",
                width: "100%",
                border: "none",
              }}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default InstallationPage;
